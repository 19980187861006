<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <h3 class="subheader">
        Configuration Status
      </h3>
      <br />
      <dl class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3">
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 items-center flex flex-wrap space-x-1"
            >
              <asom-icon :icon="'timelapse'" size="sm" />
              <span>Threshold no. of days before expiry</span>
            </dt>
            <dd
              class="text-3xl font-semibold text-gray-900 text-left grid grid-flow-col justify-items-stretch"
            >
              <p>{{ thresholdExpireInDays }} Days</p>
              <div
                v-if="canEditStationFaultExpireThreshold"
                class="justify-self-end"
                @click="editThresholdExpireClicked"
              >
                <asom-icon :icon="'external'" size="md" class="text-blue-500" />
              </div>
            </dd>
          </div>
        </aside>
      </dl>
    </article>
    <asom-modal
      v-model="showThresholdExpire"
      title="Edit Threshold Expire Days"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="thresholdExpireError"
        :error-message="thresholdExpireError"
      />
      <asom-form-field
        required
        label="Days"
        :state="inputStates('formData.threshold')"
        error="Threshold no. of days before expiry is required"
      >
        <asom-input-text
          type="number"
          min="0"
          v-model.number="formData.threshold"
          :state="inputStates('formData.threshold')"
      /></asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="editThresholdAsync"
          :disabled="isSubmittingThresholdExpireEdit"
          :loading="isSubmittingThresholdExpireEdit"
          text="Confirm"
        />
        <asom-button
          @click="toggleThresholdExpireModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  getFaultManagementConfigurationDetails,
  stationOccurrencesUpdateThresholdExpiration,
} from "../../../services/faultManagement.service";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      error: null,
      thresholdExpireInDays: null,
      showThresholdExpire: false,
      thresholdExpireError: null,
      isSubmittingThresholdExpireEdit: false,
      formData: {
        threshold: null,
      }
    };
  },
  validations() {
    return {
      formData: { 
        threshold: { required },
      }
    };
  },
  computed: {
    ...mapGetters({
      lineId: 'auth/userLineId',
      canEditStationFaultExpireThreshold:
        "auth/canEditStationFaultExpireThreshold",
    }),
  },
  mounted() {
    this.getPageData();
  },
  methods: {
    async getPageData() {
      const resp = await getFaultManagementConfigurationDetails(this.lineId);
      if (resp.success) {
        this.thresholdExpireInDays = get(resp.payload, "thresholExpiryDays", 0);
        this.error = null;
      } else {
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    editThresholdExpireClicked() {
      this.formData.threshold = this.thresholdExpireInDays;
      this.toggleThresholdExpireModal(true);
    },
    toggleThresholdExpireModal(value) {
      this.thresholdExpireError = null;
      this.showThresholdExpire = value;
    },
    async editThresholdAsync() {
      this.thresholdExpireError = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.formData.$invalid) {
        this.isSubmittingThresholdExpireEdit = true;
        const result = await stationOccurrencesUpdateThresholdExpiration({
          expiredThresholdInDays: this.formData.threshold,
          lineId: this.lineId,
        });
        if (result.success) {
          this.thresholdExpireInDays = this.formData.threshold;
          this.isSubmittingThresholdExpireEdit = false;
          this.toggleThresholdExpireModal(false);
          this.getPageData();
        } else {
          this.isSubmittingThresholdExpireEdit = false;
          this.thresholdExpireError = result.payload;
          this.$scrollTop();
        }
      } else {
        this.thresholdExpireError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
  },
};
</script>
